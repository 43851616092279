import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
import Headroom from "headroom.js";
//import 'slick-carousel';

/**
 * Anonymous function is autoexecutable.
 */

(function () {

  var myElement = document.querySelector("header");

  var options = {
    offset : 150,
    tolerance : 0,
  };
  var headroom  = new Headroom(myElement, options);
  headroom.init();

  function loadTabLabel(){
    var activeTab = $('.w-tab-link.w--current').text();
    var trimActiveTab = $.trim(activeTab);
    $('.dropdown-text').html(trimActiveTab);
  }

  loadTabLabel();

  $('.w-tab-link').on('click', function(){
    var tabLabel = $(this).text();
    var trimLabel = $.trim(tabLabel);
    $('.dropdown-text').html(trimLabel);
  });

  if($('.more-information-toggle').length > 0){
    setTimeout(function() { 
      $('.more-information-toggle').trigger('click');
    }, 1000);
  }

  let startButton = $('.start-my-search');
  if(startButton.length > 0){
    startButton.on('click', function(){
      $('.header').toggleClass('zindex1');
      $('.form-toggle').toggleClass('open');
      if($('.form-toggle').hasClass('open')){
        startButton.text('Close my search');
      }else{
        startButton.text('Start my search');
      }
    });
  }

})();
